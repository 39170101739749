<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('purchases_quotation_requests.purchases_quotation_requests')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/purchases/quotation-requests" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.title')}}</th>
                                            <td>{{data.title}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.purchase_quotation_request_code')}}</th>
                                            <td>{{data.purchase_quotation_request_code}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.purchase_quotation_request_date')}}</th>
                                            <td>{{data.purchase_quotation_request_date}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.purchase_request_due_date')}}</th>
                                            <td>{{data.purchase_request_due_date}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.added_by')}}</th>
                                            <td>{{data.added_by}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.branch')}}</th>
                                            <td>{{data.branch_name}}</td>
                                        </tr>

                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.supplier')}}</th>
                                            <td>{{data.supplier_name}}</td>
                                        </tr>
                                        
                                        <tr>
                                            <th>{{$t('status')}}</th>
                                            <td>{{data.status_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_quotation_requests.notes')}}</th>
                                            <td>{{data.notes}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.create_date}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-quotation-requests",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'purchases/quotation-requests',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'QuotationRequest',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_quotation_requests")}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>